<template>
  <div v-bind:class="{'popup-as-form': editMode, 'popup-as-view':  viewMode}">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 align-items-center">
        <h3 class="card-title align-items-start flex-column">
          <span class="title font-weight-bold">
            {{ i18n.register_title }}
          </span>
        </h3>
      </div>

      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-0">
        <b-form-group v-if="editMode">
          <div class="d-flex flex-wrap">
            <!--begin::등록자-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">{{i18n.registrant}}</span>
            </label>
            <div class="pr-14 cw-35">
              <b-form-input v-model="viewName" size="sm" type="text" placeholder="Registrant" disabled></b-form-input>
            </div>
            <!--begin::등록 일시-->

            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-info"></span>
              <span class="text">{{i18n.contact}}</span>
            </label>
            <div class="pr-14 cw-35">
              <b-form-input v-model="viewPhoneNo" size="sm" type="text" placeholder="Phone Number" disabled></b-form-input>
            </div>
            <!--begin::등록자 이메일-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-info"></span>
              <span class="text">{{i18n.email}}</span>
            </label>
            <div class="pr-14 cw-35">
                <b-form-input v-model="viewEmail" size="sm" type="text" placeholder="Email Address" disabled></b-form-input>
            </div>

            <!--begin::회사 이름-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">{{i18n.corporateName}}</span>
            </label>
            <div class="pr-14 cw-35">
              <b-form-input v-model="companyNm" size="sm" type="text" disabled></b-form-input>
            </div>
            <!--begin::팜이름-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-primary"></span>
              <span class="text">{{i18n.farmName}}</span>
            </label>
            <div class="pr-14 cw-35">
              <b-form-input v-model="farmNm" size="sm" type="text" disabled></b-form-input>
            </div>
            <!--begin::등록자 연락처-->

            <!--begin::AS요청 유형-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-danger"></span>
              <span class="text">{{i18n.type}}</span>
            </label>
            <div class="pr-14 cw-35">
              <b-form-select class="farm-form-control" v-model="claimTypeDcd" :options="claimTypeOptions" size="sm"></b-form-select>
            </div>
            <!--begin::처리 담당자-->

            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-dark"></span>
              <span class="text">{{i18n.title}}</span>
            </label>
            <div class="cw-85">
              <div class="pr-14 cw">
                <b-form-input v-model="title" class="farm-form-control" size="sm" type="text" required  :placeholder="i18n.titlePlaceholder"></b-form-input>
              </div>
            </div>

            <!--begin::AS요청 상세-->
            <label class="d-flex bullet-label cw-15 align-items-start">
              <span class="bullet bullet-bar bg-dark"></span>
              <span class="text">{{i18n.content}}</span>
            </label>
            <div class="cw-85">
              <div class="pr-14 cw">
<!--                <b-form-textarea-->
<!--                    v-model="content"-->
<!--                    class="farm-form-control"-->
<!--                    placeholder="요청 상세"-->
<!--                    rows="5"-->
<!--                    max-rows="10"-->
<!--                ></b-form-textarea>-->
              </div>
            </div>


            <div style="width: 100%">
              <div class="pr-14 cw">
                <b-form-textarea
                    v-model="content"
                    class="farm-form-control"
                    :placeholder="i18n.contentPlaceholder"
                    rows="5"
                ></b-form-textarea>
              </div>
            </div>

            <!--begin::첨부 파일-->
            <label class="d-flex bullet-label cw-15">
              <span class="bullet bullet-bar bg-danger"></span>
              <span class="text">{{i18n.attachment}}</span>
            </label>
            <div class="cw-85">
              <div class="pr-14 cw text-right">
                <b-button variant="primary" size="sm" id="addFile">
                  <span class="svg-icon svg-icon-sm svg-icon-white">
                    <inline-svg src="https://s3.ap-northeast-2.amazonaws.com/static.s3.plantee/tfarmon/media/svg/icons/Communication/Clipboard-list.svg" />
                  </span>
                  <span class="text">{{i18n.add}}</span>
                </b-button>
              </div>
            </div>


            <div style="width: 100%">
              <div class="pr-14 cw">
                <div v-show="false">
                  <vue2-dropzone ref="myVueDropzone" id="dropzone" :options="dropzoneOptions"></vue2-dropzone>
                </div>

                <div v-if="attachFiles.length === 0" class="form-section-body p-6 border rounded text-center">
                    <span class="text text-dark-50">{{i18n.attachmentPlaceholder}}</span>
                </div>
                <div v-else class="text-group-box border p-6">
                  <ul class="text-group list-unstyled mb-0">
                    <template v-for="(file, i) in attachFiles">
                      <li class="text-item row" v-bind:key="i">
                        <div class="description col-12 col-lg-12 mb-3 mb-lg-3">
                          <div class="d-flex justify-content-between">
                            <div class="file-name d-flex pr-3 flex-truncate">
                              <span class="text text-truncate farm-cursor-point farm-blue-hover" @click="downloadFile(file.path)">{{ file.name }}</span>
                            </div>
                            <div class="file-download" style="min-width: 60px">
                              <b-button class="btn-icon" size="sm" variant="primary" @click="downloadFile(file.path)">
                                <i class="la la-download"></i>
                              </b-button>

                              <b-button class="btn-icon ml-2" size="sm" variant="outline-primary" @click="deleteFile(i)">
                                <i class="la la-close"></i>
                              </b-button>
                            </div>
                          </div>
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>

              </div>
            </div>

            <!--end::첨부 파일-->
          </div>
        </b-form-group>
      </div>
      <!--end::Body-->
      <!--begin::Footer-->
      <div class="card-footer">
        <b-button v-if="(boardNo === 0 || isMine)" class="mr-3" variant="primary" size="lg" @click="saveClaim" v-bind:class="{'spinner spinner-light spinner-right': loaded}"><span class="">{{ boardNo === 0 ? i18n.add : i18n.edit}}</span></b-button>
        <b-button variant="secondary" size="lg" @click="goBack"><span class="">{{i18n.back}}</span></b-button>
      </div>
      <!--end::Footer-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import vue2Dropzone from 'vue2-dropzone';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {ACT_ADD_ALERT, ACT_SHOW_CONFIRM_ALERT} from '@/core/services/store/alert.module';
import {
  getItem,
  getItems,
  getOptions, getPhoneFormatValue,
  getResult,
  isSuccess,
  lengthCheck,
  phoneFormat,
  timestampToDate,
} from '@/core/services/funcs';
import {
  ACT_GET_CLAIM,
  ACT_GET_COMMON_CODES,
  ACT_INSERT_MY_CLAIMS, ACT_UPDATE_MY_CLAIMS,
  ACT_UPDATE_MY_CLAIMS_CONFIRM,
  API_KEY,
} from '@/core/services/variable';
import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: 'AsEdit',
  components: {
    vue2Dropzone
  },
  beforeMount() {
    this.editMode = true;
    this.boardNo = parseInt(this.$route.params.boardNo || 0)
    if(this.boardNo === 0) {
      this.boardGbText = '등록'
    }else {
      this.viewMode = true;
      this.boardGbText = '수정'
    }

    this.$store.dispatch(ACT_GET_COMMON_CODES, {dcd: 1017}).then(claimTypeResp => {
      if(lengthCheck(claimTypeResp)) this.claimTypeOptions = getOptions(getItems(claimTypeResp),this.i18n.typePlaceholder);
      if(this.boardNo > 0) {
        this.$store.dispatch(ACT_GET_CLAIM, this.boardNo).then(resp => {
          if(lengthCheck(resp)){
            const item = getItem(resp);
            this.keys.forEach(key => {
              if(item[key]) this[key] = item[key];
            });

            for(let i = 1; i < 6; i++){
              if(this['attachFileNm' + i] && this['attachFilePath' + i]){
                this.attachFiles.push({
                  name: this['attachFileNm' + i],
                  path: this['attachFilePath' + i]
                });
              }
            }
          }
        });
      }
    });

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'A/S', 'icon': 'fas fa-smile-beam' }]);
    window.scrollTo(0,0);
  },
  computed: {
    ...mapGetters(['myMno', 'myNm', 'myEmail', 'myPhoneNo', 'companyNm', 'farmNm', 'farmId']),
    viewName(){
      if(this.boardNo === 0){
        return this.myNm
      }else{
        return this.memberNm
      }
    },
    viewEmail(){
      if(this.boardNo === 0){
        return this.myEmail
      }else{
        return this.memberEmail
      }
    },
    viewPhoneNo(){
      if(this.boardNo === 0){
        return this.myPhoneNo
      }else{
        return getPhoneFormatValue(this.phoneNo)
      }
    },
    isMine(){
      return (this.myMno === this.targetMno);
    },
    isProcessing(){
      return (this.claimStDcd && this.claimStDcd !== '1016001');
    },
    hasFile(){
      return this.attachFilePath1 && this.attachFilePath1.trim();
    },
    isProcessed(){
      return (this.claimStDcd && (this.claimStDcd === '1016003' || this.claimStDcd === '1016004'));
    }
  },
  data() {
    return {
      keys: [
        'boardNo', 'targetMno', 'memberNm','memberEmail','createDt','phoneNo','regNm','regPhoneNo','regEmail',
        'answerYn','answerNm','answerContent','answerDt','claimStDcd','claimSt','claimTypeDcd','claimType',
        'title','content','attachFileNm1','attachFilePath1', 'attachFileNm2','attachFilePath2', 'attachFileNm3','attachFilePath3',
        'attachFileNm4','attachFilePath4', 'attachFileNm5','attachFilePath5'
      ],
      boardNo: 0,
      viewMode: false,
      editMode: false,
      boardGbText: '',

      // 정보
      targetMno: 0,
      memberNm: '',
      memberEmail: '',
      createDt: null,
      phoneNo: '',
      regNm: '',
      regPhoneNo: '',
      regEmail: '',
      answerYn: 'N',
      answerNm: '',
      answerContent: '',
      answerDt: null,
      claimStDcd: '',
      claimSt: '',
      claimTypeDcd: '',
      claimType: '',
      title: '',
      content: '',

      attachFiles: [],

      attachFileNm1: '',
      attachFilePath1: '',
      attachFileNm2: '',
      attachFilePath2: '',
      attachFileNm3: '',
      attachFilePath3: '',
      attachFileNm4: '',
      attachFilePath4: '',
      attachFileNm5: '',
      attachFilePath5: '',


      claimTypeOptions: [],
      dropzoneOptions: {
        url: (ApiService.apiUrl() + '/web/v1/file'),
        headers: { 'Farm-Auth-Token': JwtService.getToken(), 'Api-Key': API_KEY },
        clickable: '#addFile',
        paramName: "uploadFile", // The name that will be used to transfer the file
        success: (file, message) => {
          if(file && message && message.data){
            if(isSuccess(message.data)){
              if(lengthCheck(message.data)){
                const item = getItem(message.data)
                if(this.attachFiles.length >= 5){
                  this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.fileConfirm})
                }else{
                  this.attachFiles.push({
                    name: item.fileNm,
                    path: item.filePath
                  })
                }
              }
            }else{
              const result = getResult(message.data)
              this.$store.dispatch(ACT_ADD_ALERT, {message: result.message})
            }
          }
          this.$refs.myVueDropzone.dropzone.removeAllFiles()
        },
        error: () => {
          this.$refs.myVueDropzone.dropzone.removeAllFiles()
          this.$store.dispatch(ACT_ADD_ALERT, {title: this.i18n.error})
          // this.fileLoading = false
        }
      },
      loaded: false,
      i18n:{
        register_title: i18n.t('claim.claim')+' '+i18n.t('claim.register'),
        registrant: i18n.t('claim.registerClaim.registrant'),
        contact: i18n.t('claim.registerClaim.contact'),
        email: i18n.t('claim.registerClaim.email'),
        corporateName: i18n.t('claim.registerClaim.corporateName'),
        farmName: i18n.t('claim.registerClaim.farmName'),
        type: i18n.t('claim.registerClaim.type'),
        title: i18n.t('claim.registerClaim.title'),
        content: i18n.t('claim.registerClaim.content'),
        register : i18n.t('claim.registerClaim.register'),
        back: i18n.t('claim.registerClaim.back'),
        titlePlaceholder: i18n.t('claim.registerClaim.titlePlaceholder'),
        typePlaceholder: i18n.t('claim.registerClaim.typePlaceholder'),
        attachment: i18n.t('claim.registerClaim.attachment'),
        attachmentPlaceholder: i18n.t('claim.registerClaim.attachmentPlaceholder'),
        contentPlaceholder: i18n.t('claim.registerClaim.contentPlaceholder'),
        add: i18n.t('claim.registerClaim.add'),
        edit: i18n.t('claim.registerClaim.edit'),
        error: i18n.t('claim.registerClaim.error'),

        fileConfirm: i18n.t('claim.registerClaim.fileConfirm'),
        claimAdded: i18n.t('claim.registerClaim.claimAdded'),
        claimConfirm: i18n.t('claim.registerClaim.claimConfirm'),
        claimEditted: i18n.t('claim.registerClaim.claimEditted'),
        claimEditConfirm: i18n.t('claim.registerClaim.claimEditConfirm'),
        requestTitle: i18n.t('claim.registerClaim.requestTitle'),
        requestDesc: i18n.t('claim.registerClaim.requestDesc'),
        claimConfirmed: i18n.t('claim.registerClaim.claimConfirmed'),
        claimConfirmAsk: i18n.t('claim.registerClaim.claimConfirmAsk'),
      }
    }
  },
  methods: {
    saveClaim(){
      if(this.loaded) return;
      if(this.validateParams()){
        this.loaded = true

        const params = {
          claimTypeDcd: this.claimTypeDcd,
          title: this.title,
          content: this.content,
        }

        if(this.attachFiles.length > 0){
          this.attachFiles.forEach((attach, idx) => {
            params['attachFileNm' + (idx + 1)] = attach.name;
            params['attachFilePath' + (idx + 1)] = attach.path;
          });
        }


        if(this.boardNo === 0){
          this.insertClaim(params);
        }else{
          this.updateClaim(params);
        }
      }
    },
    insertClaim(params){
      const yesCallback = () => {
        this.$store.dispatch(ACT_INSERT_MY_CLAIMS, params)
        .then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.claimAdded, color: 'success'});
            // 성공시 페이지 이동
            this.$router.push('/smartfarm/' + this.farmId + '/as' );
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            this.loaded = false
          }
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.error});
          this.loaded = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: this.i18n.claimConfirm},
        callback: yesCallback,
        cancelCallback: () => {this.loaded = false}
      });
    },
    updateClaim(params){
      const yesCallback = () => {
        this.$store.dispatch(ACT_UPDATE_MY_CLAIMS, {
          boardNo :this.boardNo,
          params: params
        })
        .then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.claimEditted, color: 'success'});
            // 성공시 페이지 이동
            this.$router.push('/smartfarm/' + this.farmId + '/as' );
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            this.loaded = false
          }
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.error});
          this.loaded = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: this.i18n.claimEditConfirm},
        callback: yesCallback,
        cancelCallback: () => {this.loaded = false}
      });
    },
    validateParams(){
      let result = false;
      let message = ''
      if(!this.claimTypeDcd){
        message = this.i18n.typePlaceholder;
      }else if(!this.title || !this.title.trim()){
        message = this.i18n.requestTitle;
      }else if(!this.content || !this.content){
        message = this.i18n.requestDesc
      }else{
        result = true;
      }

      if(!result){
        this.$store.dispatch(ACT_ADD_ALERT, {message: message});
      }
      return result;
    },
    goBack(){
      this.$router.go(-1);
    },
    getTimeFormat(timeStamp){
      if(timeStamp) return timestampToDate(timeStamp);
      return '-';
    },
    getPhoneFormat(phoneNo){
      if(phoneNo && phoneNo.trim()){
        return phoneFormat(phoneNo)
      }
      return '-';
    },
    deleteFile(i){
      this.attachFiles.splice(i, 1);
    },
    downloadFile(attachFilePath){
      window.open(attachFilePath);
    },
    confirmClaim(){
      if(this.loaded) return;
      this.loaded = true

      const yesCallback = () => {
        this.$store.dispatch(ACT_UPDATE_MY_CLAIMS_CONFIRM, this.boardNo)
        .then(resp => {
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.claimConfirmed, color: 'success'});
            // 성공시 페이지 이동
            this.$router.push('/smartfarm/' + this.farmId + '/as' );
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: getResult(resp).message});
            this.loaded = false
          }
        })
        .catch(e => {
          console.error(e)
          this.$store.dispatch(ACT_ADD_ALERT, {message: this.i18n.error});
          this.loaded = false
        });
      }

      this.$store.dispatch(ACT_SHOW_CONFIRM_ALERT, {
        info: {title: this.i18n.claimConfirmAsk},
        callback: yesCallback,
        cancelCallback: () => {this.loaded = false}
      });

    }
  }
};
</script>
